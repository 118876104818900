'use client'

/* eslint-disable import/prefer-default-export */
import { useSearchParams } from 'next/navigation'

import { getValFromArray } from '@lib/helpers/client'

export const usePageJobId = (): string | undefined => {
  const query = useSearchParams()

  const selectLastTitleElement = getValFromArray<string>(query?.get('title') || '').split('-')
  const jobId = selectLastTitleElement[selectLastTitleElement.length - 1].slice(0, 24)

  return jobId
}
