'use client'

import dynamic from 'next/dynamic'

import useIsLoggedIn from '@hooks/user/useIsLoggedIn'

import styles from './profile.module.scss'
import useLocale from '@hooks/client/useLocale'
import Link from 'next/link'

const ProfileBtn = dynamic(() => import('@components/layout/layout/navbar/profile/profile-btn'), { ssr: false })

export default function Profile(): JSX.Element {
  const { isLoggedIn } = useIsLoggedIn()
  const country = useLocale()

  if (isLoggedIn) {
    return <ProfileBtn />
  }

  return (
    <Link href={`/${country}/login`} className={styles.link}>
      Login
    </Link>
  )
}
