import { EventHandler, useEffect } from 'react'

const useAddEventListener = (
  event: keyof WindowEventMap,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: EventHandler<any>,
): void => {
  // effect for binding event handler to the element
  useEffect(() => {
    // bind event to the element
    window.addEventListener(event, handler, { passive: event === 'scroll' })

    return (): void => window.removeEventListener(event, handler)
  }, [event, handler])
}

export default useAddEventListener
