'use client'

import { ReactNode, startTransition, useCallback, useState } from 'react'

import { usePathname } from 'next/navigation'

import useAddEventListener from '@hooks/useAddEventListener'
import useIsomorphicEffect from '@hooks/useClientSideLayoutEffect'
import { basePath } from '@lib/constants'

import styles from './wrapper.module.scss'

type WrapperProps = {
  children: ReactNode
}

export default function Wrapper({ children }: WrapperProps): JSX.Element {
  const [blueNavbarColor, setBlueNavbarColor] = useState<boolean>(false)
  const [scrollNavbar, setScrollNavbar] = useState<string>('')
  const pathname = usePathname()

  const realPathname = basePath
    ? pathname
        ?.replace(basePath, '/')
        .replace(/\/\//g, '/')
        .replace(/(\/uk|\/us)/g, '')
    : pathname?.replace(/(\/uk|\/us)/g, '')

  const handleScroll = useCallback((): void => {
    startTransition(() => {
      // 100 is height of navbar
      if (window.scrollY >= 1) {
        setScrollNavbar(styles.scrolled)
      } else {
        setScrollNavbar('')
      }
    })
  }, [])

  useAddEventListener('scroll', handleScroll)

  useIsomorphicEffect(() => {
    handleScroll()
  }, [])

  useIsomorphicEffect(() => {
    startTransition(() => {
      if (['', '/', '/smart-apply', '/maintenance', '/ai-agent'].includes(realPathname || '')) {
        setBlueNavbarColor(false)
      } else {
        setBlueNavbarColor(true)
      }
    })
  }, [pathname])

  return (
    <header className={`${styles.navbar} ${scrollNavbar} ${blueNavbarColor ? styles['navbar-to-blue'] : ''}`}>
      {children}
    </header>
  )
}
