'use client'

import { memo } from 'react'

import Image from 'next/image'

import logoIcon from '@images/navbar/logo_main-nav.svg'

import styles from './logo.module.scss'
import Link from 'next/link'
import useLocale from '@hooks/client/useLocale'

function Logo(): JSX.Element {
  const country = useLocale()
  return (
    <Link href={`/${country}/`} className={styles.logo}>
      <Image src={logoIcon} alt="SonicJobs Logo" width={131} height={33} />
    </Link>
  )
}

export default memo(Logo)
